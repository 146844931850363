@use "../core";
.table-row-clickable-item {
  color: blue;
  text-decoration-line: underline;
  cursor: pointer;
}

/* Set the width and color of the scrollbar track */
.material-react-table-body {
  ::-webkit-scrollbar {
    height: 10px;
  }

  /* Set the color of the scrollbar handle */
  ::-webkit-scrollbar-thumb {
    background: #fe5b02;
  }

  /* Set the color of the scrollbar track */
  ::-webkit-scrollbar-track {
    background: none;
  }

  /* Set the corner radius of the scrollbar handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
}

.table-group-by {
  td {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .table-group-by-cell{
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.mui-table-row-cell{
  td {
    padding-left: 15px !important;
    padding-right: 0px !important;
  }
}
