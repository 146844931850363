
.font_input_label {
        width: auto;
        font-family: Hiragino Kaku Gothic Pro;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
}

.font_input_content {
    color: #282828;
    font-family: Hiragino Kaku Gothic Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.font_button_label {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.typography-s {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &.big {
        color: #282828;
        font-family: Hiragino Kaku Gothic Pro;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    &.header {
        color: #282828;
        font-family: Hiragino Kaku Gothic Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    &.normal {
        color: #282828;
        font-family: Hiragino Kaku Gothic Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    
}
.line-space {
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.text-center {
    text-align: center;
}