@use "../core" as core;
@import "../components/text.scss";
@import "../components/appraisal.scss";


.store_name{
    padding: 0.8rem 0 0.8rem 0.8rem;
    background-color: core.$corp_main_color;
    border-top-left-radius: 8px;
    width : 280px;
    .typography-s{
        font-weight: 700;
        color: core.$white;
    }
    
}
.report_list {
    height: 10%;
    overflow: scroll;
    margin: 0;
    padding: 0;
}

.report_view_header {
    height: 90%;
}
.report_card{
    padding: 0px;
    .report_main_info_area{
        padding: 0rem 0rem 0.8rem 0rem;
    }
    .report_store_info_area{
        padding: 0.8rem 0.8rem 0.8rem 0.8rem;
        justify-content: center;
        width: 155px;
    }
    .report_car_info_area{
        flex-direction: row;
    }
    .border {
        width: 0.3px;
        border-left: solid 0.3px gray;
        margin: 10px 0;
        &.no_pad {
            padding : 0;
        }
    }
}

.report_total_info_area{
    padding: 0.8rem 0 0.3rem 0.8rem;
    justify-content: space-between;
    .each_report_summary {
        margin-top: auto;
    }
}

.report_inner{
    margin: 10px 0;
}



