@import "../components/input.scss";
@import "../components/button.scss";

.root {
    display: flex;
    width: 100%;
    height: 100vh;
    padding: 50px 0px 0px 0px;
    justify-content: center;
    align-items: center;
}

.login_form_container {
    width: 400px;
    height: 500px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.logo_wrapper {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right : auto ;
    .pad {
        flex-grow: 1;
    }
    .logo {
        flex-grow: initial;
    }
}