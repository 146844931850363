.invoice-table-container {
    width: 100%;
    overflow-x: auto;
  }
  
  .invoice-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .invoice-table th,
  .invoice-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .invoice-table th {
    background-color: #f2f2f2;
  }
  
  .invoice-table tfoot td {
    font-weight: bold;
  }
  
  .invoice-container-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .total-amount-table{
    flex: 0.5;
    margin-right: 20px;
    text-align: center;
  }
  
  .total-amount-table table{
    width: 75%;
  }
  
  .transfer-table {
    flex: 1;
    text-align: center;
  }
  
  .header-invoice-section{
    display: flex;
    justify-content: space-between;
    // align-items: center;
  }
  
  .sub-invoice-section-1 {
    flex: 1;
    margin-bottom: 50px;
  }
  
  .sub-invoice-section-2 {
    flex: 1;
    margin-bottom: 50px;
    text-align: right;
  }
  
  .sub-invoice-section-2 img{
   margin-bottom: 8px;
  }
  
  .invoice-table-header{
    background-color: #FFF9F3 !important;
    text-align: center !important;
  }
  
  .invoice-table-body{
    background-color: #ffffff !important;
    text-align: center !important;
  }
  
  .sub-invoice-section-2-details{
    text-align: left;
    margin-left: 105px;
  }

  .notranslate {
    translate: no;
  }
  