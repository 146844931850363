@import "./components/table.scss";
@import "./components/text-editor.scss";
@import "./components/pdf-invoice.scss";

.text-heading{
    background-color: #597C95;
    color: #ffffff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
}

.panel-card{
    padding: 3%;
}

.panel-card-grid{
    padding: 2%; 
}

.ctn-wrapper .MuiButton-contained{
    background: #fe5b02;
}

.ctn-wrapper .MuiButton-contained:hover{
    background: #e85100;
}

.popup-status{
    border: 1px solid #666666;
    padding: 10px;
}

.tab-mui{
    background-color: #e85100 !important;
    color: #ffffff !important;
    .Mui-selected{
        background-color: white !important;
        color: #000000 !important;
    }
}

.create-notice-panel{
    background-color: #ffffff;
    border-radius: 15px;
    margin: 0px 100px 50px 100px;
    padding: 30px;
    -webkit-box-shadow: -2px 2px 27px 18px rgba(224,224,224,1);
    -moz-box-shadow: -2px 2px 27px 18px rgba(224,224,224,1);
    box-shadow: -2px 2px 27px 18px rgba(224,224,224,1);
    
    input{
        // background-color: #ffffff;
    }
    .MuiFormControl-root {
        // background-color: #ffffff !important;
    }
}

.notice-edit{
    background-color: rgb(17,41,82) !important;
}

.notice-delete{
    background-color: #e54e24 !important;
}

.MuiTableHead-root .MuiTableCell-root {
    border-top: 1px solid rgba(224, 224, 224, 1); /* Add border to each header cell */
  }