@use "../core";
@import "../components/dropdown.scss";
@import "../components/radio.scss";
@import "../components/datetime.scss";

.mordal_background {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: #00000088;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    &.hide { display: none; }
}
.mordal_edit {
    width: 80%;
    height: 80%;
    background-color: #fff;
    overflow-y: scroll;
    overflow-x: none;
    margin : 1rem;
    padding-left : 1rem;
    padding-right: 1rem;
    z-index:99;
}
.mordal_shop {
    width: 40%;
    height: 80%;
    background-color: #fff;
    overflow: none;
    margin : 1rem;
    padding-left : 1rem;
    padding-right: 1rem;
    z-index: 99;
}
.mordal_match {
    width: 40%;
    height: 50%;
    background-color: #fff;
    overflow: none;
    margin : 1rem;
    padding-left : 1rem;
    padding-right: 1rem;
    z-index: 99;
}
.flex-status-note {
    display: flex;
    flex-direction: row;
    align-items: top;
}
.flex_note {
    flex-grow: 1;
    margin-left : 1rem;
    margin-right : 1rem;
    & > .input-norm {
        height: fit-content;
        margin: .5rem;
        & .input {
            height: 80px;
        }
    }
}
.flex_note_v2 {
    flex-grow: 1;
    margin-left : 1rem;
    margin-right : 1rem;
    & > .input-norm {
        height: fit-content;
        width: 50%;
        margin: .5rem;
        & .input {
            height: 50px;
        }
    }
}
.flex_status {
    flex-grow: 0;
    padding-left: 3rem;
    & .input-norm:last-child {
        margin-top: .7rem;
    }
}
.flex-note-appendix {
    flex-grow: 3;
    padding-left: 5rem;
    padding-right: 3rem;
    & .flex_note:last-child {
        margin-top:1rem;
    }
}
.item_label {
    margin-top: .25rem;
    margin-bottom: .25rem;
}
.column {
    width:30%;
    flex-grow: 1;
    padding: 2rem;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.left_border {
    border-left: solid 3px core.$corp_main_color;
}
.fix_button {
    bottom: 0;
    position: sticky;
    width: 100%;
    background-color: core.$white;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.button-flex {
    display : flex;
}
.mordal_shop_dropdown {
    padding : 1rem;
}
.mordal_shop_dropdown_container {
    width: 50%;
}
.mordal_match_dropdown {
    padding : 1rem;
}
.mordal_match_dropdown_container {
    width: 70%;
    padding: 1rem;
    text-align: center;
}