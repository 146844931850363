.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7f7f7;
}

.reset-password-card {
  width: 500px; /* Increased width for a wider card */
  padding: 32px; /* Adjusted padding */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  text-align: left; /* Left-aligned content */
}

.logo-wrapper {
  display: flex;
  justify-content: flex-start; /* Left-aligns the logo */
  margin-bottom: 20px;
}

.reset-password-logo {
  width: 130px;
  height: auto;
}

.reset-password-title {
  font-size: 20px; /* Increased font size for the title */
  font-weight: bold;
  margin-bottom: 24px;
}

.reset-password-input {
  border-radius: 5px;
  padding: 10px 12px;
  border: 1px solid #333;
}

.reset-password-input:focus {
  border-color: #FF2D55; /* Red focus state */
}

.reset-password-button {
  background-color: #FE5B02;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  height: 45px;
  font-weight: bold;
}

.reset-password-button.ant-btn-primary {
  background-color: #FE5B02 !important;
  border-color: #FE5B02 !important;
}

.reset-password-button.ant-btn-primary:hover {
  background-color: #E65100 !important;
  border-color: #E65100 !important;
}

.reset-password-alert {
  margin-top: 16px;
}

.reset-password-error {
  color: red;
  font-size: 12px;
}
