.car-manufacturer-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 12px;
    width: 200px;
    /* border: 2px solid grey; */
    /* border-radius: 4px; */
    /* -webkit-box-shadow: -8px -6px 5px -5px rgba(220,239,242,0.96);
    -moz-box-shadow: -8px -6px 5px -5px rgba(220,239,242,0.96);
    box-shadow: -8px -6px 5px -5px rgba(220,239,242,0.96); */
    
}

.car-manufacturer-wrapper img{
    max-width: 500px;
    height: auto;
}