.radio_parts {
    display: none;
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
    &+label {
            width: 100%;
            border: solid 3px core.$corp_main_color;
            color: core.$corp_main_color;
            background: core.$white;
            border-radius: 8px;
            margin: .1rem;
    }
    &+label.checked {
        background: core.$corp_main_color;
        color: core.$white;
        filter: none;
    }
}
$colors: (none: "", main : core.$corp_main_color, sub : core.$corp_sub_color, red : core.$red, blue: core.$blue, yellow: core.$yellow, gray: core.$gray, black: core.$black, purple: core.$purple, green: core.$green);
@each $name, $color in $colors {
    .radio_parts_#{$name} {
        display: none;
        filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
        &+label {
                width: 100%;
                border: solid 3px $color;
                color: $color;
                background: core.$white;
                border-radius: 8px;
                margin:.1rem;
                &.narrow {
                    width : 400px;
                }
        }
        &+label.checked {
            background: $color;
            color: core.$white;
            filter: none;
            &.narrow {
                width : 400px;
            }
        }
    }
}