@use "../core";

.datetime{
    padding-bottom: 5px !important;
}

.datetime fieldset.MuiOutlinedInput-notchedOutline {
    border: none ;
    border-bottom: solid 3px core.$corp_main_color !important;
    border-radius: 0px;
    &:hover {
    border-bottom: solid 3px core.$corp_main_color !important;
    }
    &.Mui-focused {
    border-bottom: solid 3px core.$corp_main_color !important;
    }
}
.MuiInputBase-root {
    border-radius: 0px;
}