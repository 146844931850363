.flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    .typography-s {
        margin: 0 1rem 0 0;
    }
}

.flex-col {
    flex-direction: column;
}

.vflex {
    display: flex;
    flex-direction: column;
}
.view_header {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}