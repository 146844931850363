$corp_main_color: #FE5B02 !default;
$corp_sub_color: #08234C;
$red: #FF2D55;
$blue: #5AC8FA;
$dark_blue:#004F98; 
$yellow:#FFCC00;
$gray: #8E8E93;
$white: #ffffff;
$black: #282828;
$shadow: #000000;
$purple:#5b3462;
$green:#0a6b14;
