@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-form-item .ant-form-item-explain-error {
  text-align: center; /* Align the message to the right */
  @apply pr-10
}
.custom-form-item-2 .ant-form-item-explain-error {
  text-align: left; /* Align the message to the right */
  @apply pl-28
}

/* TableStyles.css */
.custom-table .ant-table-thead > tr > th {
  background-color: #597b94 !important; /* Header background color */
  border-bottom: 1px solid #ffffff !important; /* Header border color */
  font-weight: 200 !important; /* Font weight */
  @apply !p-0 h-10 text-xl text-center
}

.custom-table .ant-table-tbody > tr > td {
  border-right: 1px solid #597b94 !important; /* Column border color */
}

.custom-table .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid #597b94 !important; /* Row border color */
}

/* Hide the checkbox based on the custom class */
.hide-checkbox .ant-checkbox-inner {
  display: none; /* Hides the square shape of the checkbox */
}
