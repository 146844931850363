@import "../components/appraisal.scss";
@import "../components/text.scss";
.appraisals {
    display: flex;
    flex-flow: column;
}
.page_header {
    height: 15vh;
}
.filter {
    height: 10vh;
}
.appraisal_list {
    height: 80vh;
    overflow: scroll;
}
.filter {
    margin-left: 0.5rem;
}
.justify-center {
    justify-content: center;
}
.justify-stretch {
    justify-content: stretch;
}
.justify-evenly {
    justify-content: space-evenly;
}
.justify-end {
    justify-content: flex-end;
}
.text-error {
    color: red !important;
}