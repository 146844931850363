.header-bar{
    display: flex;
    flex-direction: row;
    /* max-width: 1300px; */
    margin: 0 auto;
    align-items: center;
    margin-bottom: 20px;
}

.header-bar .logo-wrapper,
.header-bar .logout-wrapper {
    width: 50%;
}

.header-bar .logout-wrapper{
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.action-bar-wrapper{
    margin-bottom: 16px;
}

.search-wrapper .search-btn{
    width: 150px;
}

.tabs-wrapper .MuiButton-contained{
    background: #fe5b02;
}

.tabs-wrapper .MuiButton-contained:hover{
    background: #e85100;
}

.custom-checkbox {
    height: 40px; /* Set the desired height */
    display: flex; /* Ensures content aligns properly */
    align-items: center; /* Centers content vertically */
  }