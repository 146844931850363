.buyer-register-form-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 12px;
    width: 100%;
    /* border: 2px solid grey; */
    /* border-radius: 4px; */
    /* -webkit-box-shadow: -8px -6px 5px -5px rgba(220,239,242,0.96);
    -moz-box-shadow: -8px -6px 5px -5px rgba(220,239,242,0.96);
    box-shadow: -8px -6px 5px -5px rgba(220,239,242,0.96); */
    
}

.buyer-register-form-title{
    width: 100%;
    background-color: #e95412;
    text-align: center;
    font-size: 14px;
    padding: 10px;
    color: white;
}
