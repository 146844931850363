@use "../core";

.card {
    width: 95%;
    height: auto;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    margin: auto;
    margin-top: 1rem;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    .each_column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: .8rem;
        .each_parts {
            flex: 1;
        }
    }
    .customer-info-area {
        flex: 2;
    }
    .car-info-area{
        flex: 3;
    }
    .buttons {
        justify-content: space-between;
    }
}
.appraisals {
    overflow: scroll;
}