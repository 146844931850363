@use "../core";
.input-norm {
    width: 100%;
    height: 45px;
    flex-shrink: 0;
    > select, input {
        padding-top : 0rem;
        padding-bottom: 0rem;
    }
    &.sparse {
        margin : 2rem;
    }
    &.narrow {
        margin-top: .1rem;
        margin-bottom: .1rem;
        width: 120px;
    }
    &.narrow-v2 {
        margin-top: .1rem;
        margin-bottom: .1rem;
        width: 152px;
    }
    .label {
        display: flex;
        width: 100%;
        flex-direction: column;
        flex-shrink: 0;
        color: #000;
    }
    .input {
        width : 100%;
        height: auto;
        border-top : none;
        border-left: none;
        border-right : none;
        border-bottom: solid 3px core.$corp_main_color
    }
    :focus {
        outline: solid .5px core.$corp_main_color;
        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
        border-radius: 0px;
    }
}
.filter_input {
    margin-left: .5rem;
    margin-right: .5rem;
}